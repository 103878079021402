import { Component, OnInit } from '@angular/core';

import { PANELS } from '../PANELS';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

	panels = PANELS;

	constructor() { }

	ngOnInit() {
		console.log(this.panels);
	}

}
