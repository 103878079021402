import { Panel } from './classes/panel';

export const PANELS: Panel[] = [
	{
		title: "Real Estate Agent",
		image: "shutterstock_1173031984.jpg",
		copy: "Lectus nisl tempore quo quaerat, minim imperdiet mus magnis!"
	},
	{
		title: "Lawyer",
		image: "Benefits-of-Hiring-A-Personal-Injury-Lawyer.jpg",
		copy: "Lectus nisl tempore quo quaerat, minim imperdiet mus magnis!"
	},
	{
		title: "Banker",
		image: "images.jpeg",
		copy: "Lectus nisl tempore quo quaerat, minim imperdiet mus magnis!"
	},
	{
		title: "Insurance",
		image: "Iowa_Flooding_Reuters.jpg",
		copy: "Lectus nisl tempore quo quaerat, minim imperdiet mus magnis!"
	},
	{
		title: "Planner",
		image: "showimage.png",
		copy: "Lectus nisl tempore quo quaerat, minim imperdiet mus magnis!"
	},
	{
		title: "Government",
		image: "city_hall_at_dusk.jpg",
		copy: "Lectus nisl tempore quo quaerat, minim imperdiet mus magnis!"
	},
];