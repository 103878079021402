import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent {

	// title = 'test-civicatlas-com';

	mission = "Our mission is to lectus nisl tempore quo quaerat, sint soluta quae minim natoque imperdiet mus magnis inventore aliquip, exercitation molestiae! Explicabo in quasi.";
}
