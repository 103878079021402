import { Component, OnInit, Input } from '@angular/core';
import { Panel } from '../classes/panel';

@Component({
	selector: 'app-panel',
	templateUrl: './panel.component.html',
	styleUrls: ['./panel.component.less']
})
export class PanelComponent implements OnInit {

	@Input() panel: Panel;

	constructor() {
	}

	ngOnInit() {
	}
}
